import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Página no encontrada" />
    <br/>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `0 1.0875rem 1.45rem`,
      }}
    >
      <h1>NO ENCONTRADA</h1>
      <p>La página a la que intentaste acceder no existe.</p>
    </div>
  </Layout>
)

export default NotFoundPage